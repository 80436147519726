import React from 'react';
import "./Sidebar.css"
import {providers, regions} from "../data";
import {Link} from "react-router-dom";

function List({elements, active, type})
{
	return (
		<div className="list">
			{elements.map(element => <Link className={"list-link" + (active && element.toLowerCase() === active.toLowerCase() ? " active" : "")} to={"/" + type + "/" + element}>{element}</Link>)}
		</div>
	)
}

export default function Sidebar({activeType, activeElement})
{
	const [type, setType] = React.useState(activeType);
	const [searched, setSearched] = React.useState("");

	return (
		<div className="sidebar">
			<div className="buttons">
				<button className={"type-button" + (type === "provider" ? " active" : "")} onClick={() => setType("provider")}>Operatori</button>
				<button className={"type-button" + (type === "region" ? " active" : "")} onClick={() => setType("region")}>Regioni</button>
			</div>
			<input type="text" name="search" placeholder="Cerca" onChange={event => setSearched(event.target.value)}/>
			<List elements={type === "provider" ? providers(searched) : regions(searched)} active={activeElement} type={type}/>
		</div>
	)
}