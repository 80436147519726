import React from 'react';
import "./Result.css"
import {findProvider, networkTypesAndEmojis} from "../data";
import Card from "./Card";

export default function ResultProvider({provider})
{
	const data = findProvider(provider);
	const networkTypes = [...new Set(data.flatMap(record => record.type).filter(type => type).sort())];
	const results = networkTypes.map(type =>
	{
		return (
			<>
				<h2>{type} {networkTypesAndEmojis().get(type)}</h2>
				<div className="cards">
					{data.filter(record => record.type === type).map(record => (<Card link={"/region/" + record.region} text={record.notes} title={record.region}/>))}
				</div>
			</>
		)
	})

	return (
		<div className="result">
			<h1>{provider}</h1>
			<>{results}</>
		</div>
	)
}