import React from 'react';
import Navbar from "./Navbar";
import Main from "./Main";
import {useParams} from "react-router-dom";
import Footer from "./Footer";

export default function App({type})
{
	const {element} = useParams();
	const [isMobile, setIsMobile] = React.useState(window.innerWidth < 800);
	const [openMenu, setOpenMenu] = React.useState(!isMobile || element === undefined);

	window.addEventListener("resize", () => setIsMobile(window.innerWidth < 800));

	React.useEffect(() => setOpenMenu(!isMobile || element === undefined), [element, isMobile])

	return (
		<>
			<Navbar changeMenu={setOpenMenu} openMenu={openMenu} showMenuButton={isMobile /* && element === undefined */}/>
			<Main openMenu={openMenu} selectedElement={element} selectedType={type}/>
			<Footer/>
		</>
	)
}