import React from 'react';
import "./Navbar.css"
import {Link} from "react-router-dom";
import MenuButton from "./MenuButton";

export default function Navbar({changeMenu, openMenu, showMenuButton})
{
	return (
		<nav>
			{showMenuButton && <MenuButton open={openMenu} action={() => changeMenu((current) => !current)} />}
			<h3><Link to="/">Operatori locali</Link></h3>
		</nav>
	)
}