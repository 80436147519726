import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import App from "./components/App";
import {setData} from "./data";


function Navigator({to})
{
	const navigate = useNavigate();
	React.useEffect(() => {
		navigate(to);
	}, [navigate, to])
}

const root=ReactDOM.createRoot(document.getElementById('root'));
setData().then(()=> {
	root.render(
		<React.StrictMode>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App/>}/>
					<Route path="/provider/:element" element={<App type="provider"/>}/>
					<Route path="/region/:element" element={<App type="region"/>}/>
					<Route path="*" element={<Navigator to={"/"}/>} />
				</Routes>
			</BrowserRouter>
		</React.StrictMode>
	);
})
