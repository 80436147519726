import React from 'react';
import "./Main.css"
import Sidebar from "./Sidebar";
import ResultProvider from "./ResultProvider";
import ResultRegion from "./ResultRegion";

export default function Main({selectedType, selectedElement, openMenu})
{
	return (
		<div className="container">
			{openMenu && <Sidebar activeType={selectedType === undefined ? "provider" : selectedType} activeElement={selectedElement} />}
			{selectedType === "provider" && <ResultProvider provider={selectedElement} />}
			{selectedType === "region" && <ResultRegion regionName={selectedElement} />}
		</div>
	)
}