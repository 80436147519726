import "./MenuButton.css"

export default function MenuButton({action, open})
{
	return (
		<div className={"containerButton" + (open ? " change" : "")} onClick={action}>
			<div className="bar1"></div>
			<div className="bar2"></div>
			<div className="bar3"></div>
		</div>
	)
}