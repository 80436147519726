let data;

export function setData()
{
	return fetch("/data.json").then(resp => resp.json()).then(resp => data = resp);
}

export default function rawData()
{
	return data;
}

export function providers(query)
{
	return [...new Set(rawData().flatMap(record => record.provider).filter(provider => provider.toLowerCase().includes(query.toLowerCase())).sort())];
}

export function regions(query)
{
	return [...new Set(rawData().flatMap(record => record.region).filter(provider => provider.toLowerCase().includes(query.toLowerCase())).sort())];
}

export function findProvider(providerName)
{
	return rawData().filter(record => record.provider.toLowerCase() === providerName.toLowerCase());
}

export function findRegion(regionName)
{
	return rawData().filter(record => record.region.toLowerCase() === regionName.toLowerCase());
}

export function networkTypesAndEmojis()
{
	const result = new Map();
	result.set("FWA", "📡");
	result.set("FTTH", "🚀");
	return result;
}