import React from 'react';
import {Link} from "react-router-dom";
import "./Card.css"

export default function Card({title, link, text})
{
	const footer = <div className="card-footer"><p>{text}</p></div>
	return (
		<Link to={link} className="card">
			<h2>{title}</h2>
			{text !== "" ? footer : <></>}
		</Link>
	)
}