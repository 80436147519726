import React from 'react';
import "./Result.css"
import {findRegion, networkTypesAndEmojis} from "../data";
import Card from "./Card";

export default function ResultRegion({regionName})
{
	const data = findRegion(regionName);
	const networkTypes = [...new Set(data.flatMap(record => record.type).filter(type => type).sort())];
	const results = networkTypes.map(type =>
	{
		return (
			<>
				<h2>{type} {networkTypesAndEmojis().get(type)}</h2>
				<div className="cards">
					{data.filter(record => record.type === type).map(record => (<Card link={"/provider/" + record.provider} text={record.notes} title={record.provider}/>))}
				</div>
			</>
		)
	})

	return (
		<div className="result">
			<h1>{regionName}</h1>
			<>{results}</>
		</div>
	)
}